<template>
  <div class="experiences">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        :md="paramID ? 6 : 12"
        v-if="!$vuetify.breakpoint.mobile || !paramID"
      >
        <list-items
          :listItems="listItems"
          @paginationHandler="fetchList"
          :total="total"
          :districts="districts"
          :loading="loading"
          @searchExperience="searchExperience"
          @reloadData="reloadData"
          :canAddExperience="false"
          :md="paramID ? 6 : 3"
          @itemClicked="itemClicked"
        ></list-items>
      </v-col>
      <v-col cols="12" :md="6">
        <item-body
          :districts="districts"
          v-if="itemID && itemID.data && itemID.data._id"
          :item="itemID"
          @editExperience="editExperience"
          @reloadData="reloadData"
          @collapse="collapse"
          :actions="['edit', 'restore']"
          @restoreHandler="restoreExperience"
        ></item-body>
      </v-col>
    </v-row>
    <v-dialog v-model="addExperienceDialog" max-width="750px">
      <new-experience-dialog
        :key="addExperienceDialog"
        @close="addExperienceDialog = false"
        @reloadData="reloadData"
      ></new-experience-dialog>
    </v-dialog>
    <v-dialog v-model="editExperienceDialog" max-width="750px">
      <new-experience-dialog
        v-if="itemID && itemID.data"
        :key="editExperienceDialog"
        @close="editExperienceDialog = false"
        @reloadData="reloadData"
        :itemID="itemID && itemID.data ? itemID.data._id : undefined"
      ></new-experience-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ListItems from "@/components/activity/ListItems";
import ItemBody from "@/components/activity/ItemBody";
import NewExperienceDialog from "@/components/dialogs/Experience/Index.vue";

export default {
  components: {
    ListItems,
    ItemBody,
    NewExperienceDialog
  },
  data: () => ({
    page: 1,
    paramID: "",
    listItems: [],
    itemID: undefined,
    total: 0,
    districts: [],
    loading: true,
    searchWord: undefined,
    editExperienceDialog: false,
    addExperienceDialog: false
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    reloadData() {
      this.fetchList(this.page);
      this.fetchItemByID();
    },
    addExperience() {
      this.addExperienceDialog = true;
    },
    editExperience() {
      this.editExperienceDialog = true;
    },
    async searchExperience(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.listItems.length > 0) {
          this.paramID = this.listItems[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.itemID = undefined;
        }
      }
    },
    async fetchList(page) {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      if (!page) {
        page = 1;
      }
      this.page = page;
      let { data } = await this.$axios.get(
        `deleted/activity?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }pageSize=12&pageNumber=${page}`,
        { cancelToken: this.source.token }
      );
      if (data) {
        this.listItems = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(`/activity/${this.paramID}`);

        if (data) {
          this.itemID = data;
        }
      }
    },
    async fetchDistricts() {
      let { data } = await this.$axios.get("activity/all/selectors");
      if (data) {
        this.districts = data.districts;
      }
    },
    collapse() {
      this.$router.push("/deleted-experiences");
      this.paramID = undefined;
    },
    itemClicked(item) {
      this.$router.push(`/deleted-experiences/${item._id}`);
    },
    async restoreExperience(item) {
      let { data } = await this.$axios.put(`host/activity/${item._id}`, {
        deleted: false
      });
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Restored Succesfully",
          color: `success`
        });
        this.collapse()
        this.reloadData()
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: `error`
        });
      }
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
    await this.fetchDistricts();
  }
};
</script>

<style lang="scss" scoped>
.experiences {
  background: white;
  height: 100vh;
  overflow: hidden;
}
</style>
